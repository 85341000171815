import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, UserGroupDto, UsersApi } from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class UserGroupsStore extends PageableCrudStore<UserGroupDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }
  protected async loadModelById(id: string): Promise<UserGroupDto> {
    const res = await this.axiosApiClient.get(UsersApi).getUserGroup({ id });
    return res.data;
  }
  protected deleteModelById(id: string): Promise<void> {
    return this.axiosApiClient
      .get(UsersApi)
      .deleteUserGroup({ id })
      .then((res) => res.data);
  }
  protected deleteModelsByIds(ids: string[]): Promise<void> {
    return this.axiosApiClient
      .get(UsersApi)
      .deleteManyUserGroups({ ids })
      .then((res) => res.data);
  }
  protected createModel(
    model: Omit<UserGroupDto, "id" | "context" | "createdAt" | "lastUpdatedAt">
  ): Promise<UserGroupDto> {
    return this.axiosApiClient
      .get(UsersApi)
      .createUserGroup({ userGroup: model })
      .then((res) => res.data);
  }
  protected patchModel(id: string, model: any): Promise<UserGroupDto> {
    return this.axiosApiClient
      .get(UsersApi)
      .patchUserGroup({ id, userGroup: model })
      .then((res) => res.data);
  }
  protected putModel(model: UserGroupDto): Promise<UserGroupDto> {
    return this.axiosApiClient
      .get(UsersApi)
      .putUserGroup({ id: model.id, userGroup: model })
      .then((res) => res.data);
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<UserGroupDto>> {
    const res = await this.axiosApiClient.get(UsersApi).getUserGroups(query);
    return res.data;
  }
}
