import { effect } from "@angular/core";
import { PushNotificationService } from "@smallstack/client-common";
import { Store } from "@smallstack/typesystem-client";

export function longPollingFallback(store: Store<any>, pushNotificationService: PushNotificationService): void {
  let timeout: NodeJS.Timeout | undefined;

  async function syncWithTimeout() {
    await store.reload();
    timeout = setTimeout(() => {
      void syncWithTimeout();
    }, 10000);
  }

  effect(() => {
    if (pushNotificationService.pushPermissionBlocked()) void syncWithTimeout();
    else if (timeout) clearInterval(timeout);
  });
}
