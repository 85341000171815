import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, CreateRoleDto, JsonPatchDto, PermissionsApi, RoleDto } from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class RoleStore extends PageableCrudStore<RoleDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<RoleDto>> {
    const res = await this.axiosApiClient.get(PermissionsApi).getRoles({ ...query });
    return res.data;
  }
  protected async loadModelById(id: string): Promise<RoleDto> {
    if (!this.hasId(id)) await this.preload();
    return this.getById(id);
  }
  protected async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(PermissionsApi).deleteRole({ id });
    return res.data;
  }
  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(PermissionsApi).deleteManyRoles({ ids });
    return res.data;
  }
  protected async createModel(createRole: CreateRoleDto): Promise<RoleDto> {
    const res = await this.axiosApiClient.get(PermissionsApi).createRole({ createRole });
    return res.data;
  }
  protected async patchModel(id: string, model: Partial<RoleDto> | Array<JsonPatchDto>): Promise<RoleDto> {
    const res = await this.axiosApiClient.get(PermissionsApi).patchRole({ id, role: model });
    return res.data;
  }
  protected async putModel(model: RoleDto): Promise<RoleDto> {
    const res = await this.axiosApiClient.get(PermissionsApi).putRole({ id: model.id, role: model });
    return res.data;
  }
}
