<div data-test="verification-messages">
  @switch (state().type) {
    @case ("verifying") {
      <div class="alert alert-info">
        <div>E-Mail Adresse wird verifiziert, bitte warten...</div>
      </div>
    }
    @case ("verified") {
      <div class="alert alert-success">
        <div>Ihre E-Mail Adresse wurde erfolgreich verifiziert!</div>
      </div>
    }
    @case ("error") {
      <div class="alert border-error border-4 bg-red-100">
        <div>Ihre E-Mail Adresse konnte leider nicht verifiziert werden!</div>
      </div>
    }
    @case ("unverified") {
      <div class="alert alert-warning flex flex-col md:flex-row justify-between">
        <div>Bitte verifizieren Sie Ihre E-Mail Adresse: {{ state().emailAddress }}</div>
        <button class="btn btn-primary" [loadingFn]="sendVerificationMail()">Verifizierungsmail erneut senden</button>
      </div>
    }
  }
</div>
