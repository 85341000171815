import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, UserDto, UsersApi } from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";

@Injectable({ providedIn: "root" })
export class UserStore extends PageableCrudStore<UserDto> {
  constructor(private axiosApiClient: AxiosApiClient) {
    super();
  }
  protected override async loadModelById(id: string): Promise<UserDto> {
    const res = await this.axiosApiClient.get(UsersApi).getUser({ id });
    return res.data;
  }
  protected override async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(UsersApi).deleteUser({ id });
    return res.data;
  }
  protected override async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(UsersApi).deleteManyUsers({ ids });
    return res.data;
  }
  protected override async createModel(
    model: Omit<UserDto, "id" | "context" | "createdAt" | "lastUpdatedAt">
  ): Promise<UserDto> {
    const res = await this.axiosApiClient.get(UsersApi).createUser({ createUser: model });
    return res.data;
  }
  protected override async patchModel(id: string, model: any): Promise<UserDto> {
    const res = await this.axiosApiClient.get(UsersApi).patchUser({ id, user: model });
    return res.data;
  }
  protected override async putModel(model: UserDto): Promise<UserDto> {
    const res = await this.axiosApiClient.get(UsersApi).putUser({ id: model.id, user: model });
    return res.data;
  }
  protected async loadModels(query: ApiQueryRequest): Promise<Page<UserDto>> {
    const res = await this.axiosApiClient.get(UsersApi).getUsers(query);
    return res.data;
  }
}
