import { Injectable, computed, effect, signal } from "@angular/core";

export enum BadgeName {
  UnreadMessageThreads = "UnreadMessageThreads"
}

export interface BadgeMutator {
  setBadge: (count: number) => void;
  clearBadge: () => void;
}

@Injectable({ providedIn: "root" })
export class PwaBadgeService {
  private badgeMutators: { [name: string]: BadgeMutator } = {};
  private badgeCounts = signal<{ [name: string]: number }>({});
  public badgeCount = computed(() => {
    return Object.values(this.badgeCounts()).reduce((acc, count) => acc + count, 0);
  });

  constructor() {
    if ("setAppBadge" in navigator) {
      effect(async () => {
        await navigator.setAppBadge(this.badgeCount());
      });
    }
  }

  public getBadgeMutator(name: string): BadgeMutator {
    if (!this.badgeMutators) {
      this.badgeMutators = {};
    }
    return (
      this.badgeMutators[name] || {
        setBadge: (count: number) => this.setBadge(name, count),
        clearBadge: () => this.clearBadge(name)
      }
    );
  }

  private setBadge(name: string, count: number) {
    this.badgeCounts.update((counts) => {
      counts[name] = count;
      return counts;
    });
  }

  private clearBadge(name: string) {
    this.badgeCounts.update((counts) => {
      counts[name] = 0;
      return counts;
    });
  }
}
