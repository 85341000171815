import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog, MatDialogModule } from "@angular/material/dialog";
import { LoadingElementDirective } from "@smallstack/common-components";
import { I18nComponent } from "@smallstack/i18n-components";
import { IconComponent } from "@smallstack/theme-components";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";
import { firstValueFrom } from "rxjs";
import { CreateUserDialogComponent } from "../../components/create-user-dialog/create-user-dialog.component";
import { CustomerStore } from "../../stores/customer.store";
import { RoleStore } from "../../stores/role.store";

@Widget({
  name: "CreateUserDialogBtn",
  templateName: "Benutzer Erstellen Button",
  templateDescription: "Zeigt einen Button, welcher den Benutzer Erstellen Dialog öffnet.",
  icon: "user",
  dataSchema: {
    type: "object",
    properties: {
      icon: {
        type: "string",
        default: "user",
        title: "Icon",
        "x-schema-form": {
          widget: "icon"
        }
      },
      text: {
        type: "string",
        title: "Text",
        default: "Benutzer anlegen"
      },
      btnAsBlock: {
        type: "boolean",
        title: "Button auf voller Breite anzeigen",
        default: true
      }
    }
  }
})
@Component({
  selector: "smallstack-user-create-user-dialog-btn-widget",
  standalone: true,
  imports: [CommonModule, MatDialogModule, I18nComponent, IconComponent, LoadingElementDirective],
  templateUrl: "./create-user-dialog-btn-widget.component.html",
  styleUrls: ["./create-user-dialog-btn-widget.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateUserDialogBtnWidgetComponent extends BaseWidgetComponent {
  constructor(
    private matDialog: MatDialog,
    private customerStore: CustomerStore,
    private roleStore: RoleStore
  ) {
    super();
  }

  protected openCreateUserDialog() {
    return async (): Promise<void> => {
      await firstValueFrom(this.matDialog.open(CreateUserDialogComponent).afterClosed());
      await this.roleStore.preload({ force: true });
      await this.customerStore.preload({ force: true });
    };
  }
}
