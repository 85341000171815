import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed } from "@angular/core";
import { RoleDto } from "@smallstack/axios-api-client";
import { I18nComponent } from "@smallstack/i18n-components";
import { InlineTranslation } from "@smallstack/i18n-shared";
import { TYPE_ROLES } from "@smallstack/typesystem";
import { injectStore } from "@smallstack/typesystem-client";
import { BaseWidgetComponent, Widget } from "@smallstack/widget-core";

@Widget({
  name: "RolesAsTags",
  templateName: "Benutzer Rollen",
  templateDescription: "Zeigt die Rollen eines Benutzers als Tag-Liste an.",
  icon: "price-tag--v1",
  dataSchema: {
    type: "object",
    properties: {
      contextUserId: {
        type: "string",
        title: "Benutzer ID Kontext Variable"
      }
    }
  }
})
@Component({
  selector: "smallstack-user-roles-tags-widget",
  standalone: true,
  imports: [CommonModule, I18nComponent],
  templateUrl: "./user-roles-tags-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserRolesTagsWidgetComponent extends BaseWidgetComponent {
  private roleStore = injectStore<RoleDto>({ typePath: TYPE_ROLES });

  protected tags = computed<Array<string | InlineTranslation>>(() => {
    const userId = this.getContextReplacedData("contextUserId");
    const roles = this.roleStore.getAll();
    if (userId && roles) {
      return roles.filter((role) => role.userIds.includes(userId)).map((role) => role.name);
    }
  });
}
