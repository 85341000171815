import { CommonModule } from "@angular/common";
import { Injector, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ContextService, LoadingElementDirective, StoreRegistry } from "@smallstack/common-components";
import { SmallstackI18nModule } from "@smallstack/i18n-components";
import { SmallstackLinkModule } from "@smallstack/link-components";
import { SmallstackTextModule } from "@smallstack/text-components";
import { SmallstackThemeModule } from "@smallstack/theme-components";
import { TYPE_ROLES, TYPE_USER_GROUPS } from "@smallstack/typesystem";
import { SmallstackFormCoreModule, SmallstackWidgetsModule, WidgetRegistry } from "@smallstack/widget-core";
import { AvatarComponent } from "./components/avatar/avatar.component";
import { LoginComponent } from "./components/login/login.component";
import { LogoutWarningComponent } from "./components/logout-warning/logout-warning.component";
import { UsernameLoginComponent } from "./components/username-login/username-login.component";
import { EnabledForPermissionDirective } from "./directives/permission-directives/enabled-for-permission.directive";
import { ShowForPermissionDirective } from "./directives/permission-directives/show-for-permission.directive";
import { VisibleIfAdvancedModeActiveDirective } from "./directives/visible-if-advanced-mode-active.directive";
import { MailingOptInOutComponent } from "./mailing/mailing-opt-in-out/mailing-opt-in-out.component";
import { PasswordFieldComponent } from "./password-field/password-field.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";
import { MatPasswordStrengthModule } from "./password-strength/mat-password-strength.module";
import { DisplayNamePipe } from "./pipes/display-name.pipe";
import { TenantLabelPipe } from "./pipes/tenant-label.pipe";
import { MyMessageThreadsStore } from "./stores/my-message-threads.store";
import { MyMessagesStore } from "./stores/my-messages.store";
import { RoleStore } from "./stores/role.store";
import { UserGroupsStore } from "./stores/user-groups.store";
import { TooltipComponent } from "./tooltip/tooltip.component";
import { VerificationMessageComponent } from "./verification-message/verification-message.component";
import { AvatarEditorWidgetComponent } from "./widgets/avatar-editor-widget/avatar-editor-widget.component";
import { AvatarWidgetComponent } from "./widgets/avatar-widget/avatar-widget.component";
import { CreateUserDialogBtnWidgetComponent } from "./widgets/create-user-dialog-btn-widget/create-user-dialog-btn-widget.component";
import { EmailVerificationBannerComponent } from "./widgets/email-verification-banner/email-verification-banner.component";
import { UserConfigurationEditorWidgetComponent } from "./widgets/user-configuration-editor-widget/user-configuration-editor-widget.component";
import { UserGroupsListWidgetComponent } from "./widgets/user-groups-list-widget/user-groups-list-widget.component";
import { UserRolesTagsWidgetComponent } from "./widgets/user-roles-tags-widget/user-roles-tags-widget.component";
import { VisibleForRoleWidgetComponent } from "./widgets/visible-for-role-widget/visible-for-role-widget.component";

export const messagingStoreProviders = [
  {
    provide: MyMessageThreadsStore,
    useFactory: (injector: Injector, contextService: ContextService): MyMessageThreadsStore => {
      return new MyMessageThreadsStore(injector, contextService);
    },
    deps: [Injector, ContextService]
  },
  {
    provide: MyMessagesStore,
    useFactory: (injector: Injector, contextService: ContextService): MyMessagesStore => {
      return new MyMessagesStore(injector, contextService);
    },
    deps: [Injector, ContextService]
  }
];

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatInputModule,
    FormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatPasswordStrengthModule,
    MatSelectModule,
    MatExpansionModule,
    SmallstackI18nModule,
    SmallstackThemeModule,
    SmallstackLinkModule,
    SmallstackWidgetsModule,
    SmallstackFormCoreModule,
    SmallstackTextModule,
    EmailVerificationBannerComponent,
    PasswordFieldComponent,
    PasswordResetComponent,
    AvatarComponent,
    ShowForPermissionDirective,
    VisibleIfAdvancedModeActiveDirective,
    LoadingElementDirective,
    TenantLabelPipe,
    LoginComponent,
    UsernameLoginComponent,
    LogoutWarningComponent
  ],
  declarations: [
    EnabledForPermissionDirective,
    TooltipComponent,
    VerificationMessageComponent,
    MailingOptInOutComponent,
    DisplayNamePipe
  ],
  exports: [
    EnabledForPermissionDirective,
    PasswordFieldComponent,
    PasswordResetComponent,
    ShowForPermissionDirective,
    TooltipComponent,
    VerificationMessageComponent,
    MailingOptInOutComponent,
    VisibleIfAdvancedModeActiveDirective,
    DisplayNamePipe,
    LogoutWarningComponent,
    LoginComponent,
    UsernameLoginComponent,
    AvatarComponent
  ]
})
export class SmallstackUserModule {
  constructor(
    widgetRegistry: WidgetRegistry,
    storeRegistry: StoreRegistry,
    roleStore: RoleStore,
    userGroupsStore: UserGroupsStore
  ) {
    // register stores
    storeRegistry.registerStore(TYPE_ROLES, roleStore);
    storeRegistry.registerStore(TYPE_USER_GROUPS, userGroupsStore);

    // register widgets
    widgetRegistry.registerWidget(EmailVerificationBannerComponent);
    widgetRegistry.registerWidget(UserRolesTagsWidgetComponent);
    widgetRegistry.registerWidget(AvatarWidgetComponent);
    widgetRegistry.registerWidget(AvatarEditorWidgetComponent);
    widgetRegistry.registerWidget(VisibleForRoleWidgetComponent);
    widgetRegistry.registerWidget(CreateUserDialogBtnWidgetComponent);
    widgetRegistry.registerWidget(UserGroupsListWidgetComponent);
    widgetRegistry.registerWidget(UserConfigurationEditorWidgetComponent);
  }
}
