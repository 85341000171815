<div class="flex flex-col justify-center place-items-center gap-3">
  <smallstack-avatar [url]="avatarUrl$ | async" size="xl"></smallstack-avatar>
  <div class="flex flex-row gap-3">
    @if (avatarUrl$ | async) {
      <button [loadingFn]="removeAvatar()" class="btn btn-error btn-sm">
        <i class="far fa-trash"></i>
      </button>
    }
    <input class="hidden" type="file" (change)="uploadAvatar($event)" accept="image/*" #uploadInput />
    <button
      (click)="uploadInput.click(); isUploading = true"
      class="btn btn-primary btn-sm"
      [ngClass]="{ loading: isUploading }"
      >{{ isUploading ? "wird hochgeladen" : "Neues Bild hochladen" }}</button
    >
  </div>
</div>
