import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, computed, inject } from "@angular/core";
import { RoleDto } from "@smallstack/axios-api-client";
import { Logger } from "@smallstack/core-common";
import { TYPE_ROLES, WIDGET_FORM_INPUT_SELECT } from "@smallstack/typesystem";
import { injectStore } from "@smallstack/typesystem-client";
import { BaseWidgetComponent, Widget, WidgetChildComponent } from "@smallstack/widget-core";
import { UserService } from "../../services/user.service";

@Widget({
  name: "VisibleForRole",
  templateName: "Rollen Container",
  templateDescription: "Dieser Container wird nur angezeigt, wenn der angemeldete Benutzer eine definierte Rolle hat.",
  icon: "user",
  dataSchema: {
    type: "object",
    properties: {
      roleId: {
        type: "string",
        title: "Rolle",
        "x-schema-form": {
          inputWidget: WIDGET_FORM_INPUT_SELECT,
          type: TYPE_ROLES
        }
      }
    }
  }
})
@Component({
  selector: "smallstack-visible-for-role-widget",
  standalone: true,
  imports: [CommonModule, WidgetChildComponent],
  templateUrl: "./visible-for-role-widget.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisibleForRoleWidgetComponent extends BaseWidgetComponent {
  private userService = inject(UserService);
  private roleStore = injectStore<RoleDto>({ typePath: TYPE_ROLES });

  protected hasRole = computed(() => {
    const data = this.data();
    const roles = this.roleStore.getAll();
    const currentUserId = this.userService.currentUserId();

    if (data && data.roleId && roles instanceof Array && roles.length > 0 && currentUserId) {
      const role = roles.find((role) => role.id === data.roleId);
      if (!role) {
        Logger.error("VisibleForRoleWidget", "Could not find role by id: " + data.roleId);
        return false;
      }
      return role.userIds?.includes(currentUserId);
    }
    return false;
  });
}
