<button
  [loadingFn]="openCreateUserDialog()"
  class="btn btn-primary flex flex-row gap-1 justify-center place-items-center"
  [ngClass]="{ 'btn-block': data()?.btnAsBlock === true }"
>
  @if (data()?.icon) {
    <smallstack-icon [name]="data().icon" size="24" style="margin: unset"></smallstack-icon>
  }
  @if (data()?.text) {
    <smallstack-i18n [data]="data().text"></smallstack-i18n>
  }
</button>
