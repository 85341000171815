import { Injectable } from "@angular/core";
import { ApiQueryRequest, Page } from "@smallstack/api-shared";
import { AxiosApiClient, CreateUserDto, UserDto, UsersApi } from "@smallstack/axios-api-client";
import { TypeDto } from "@smallstack/axios-api-client";
import { PageableCrudStore } from "@smallstack/store";
import { TypeSupport, TYPE_USERS } from "@smallstack/typesystem";
import { TypeService } from "@smallstack/typesystem-client";

/**
 * Stores customers (users inside of projects)
 */
@Injectable({ providedIn: "root" })
export class CustomerStore extends PageableCrudStore<UserDto> implements TypeSupport<TypeDto> {
  constructor(
    private axiosApiClient: AxiosApiClient,
    private typeService: TypeService
  ) {
    super();
  }

  public async getType(): Promise<TypeDto> {
    return this.typeService.getTypeByPath(TYPE_USERS);
  }

  protected async loadModels(query: ApiQueryRequest): Promise<Page<UserDto>> {
    const res = await this.axiosApiClient.get(UsersApi).getUsers(query);
    return res.data;
  }

  protected async loadModelById(id: string): Promise<UserDto> {
    const res = await this.axiosApiClient.get(UsersApi).getUser({ id });
    return res.data;
  }

  protected async deleteModelById(id: string): Promise<void> {
    const res = await this.axiosApiClient.get(UsersApi).deleteUser({ id });
    return res.data;
  }

  protected async deleteModelsByIds(ids: string[]): Promise<void> {
    const res = await this.axiosApiClient.get(UsersApi).deleteManyUsers({ ids });
    return res.data;
  }

  protected async createModel(user: CreateUserDto): Promise<UserDto> {
    const res = await this.axiosApiClient.get(UsersApi).createUser({ createUser: user });
    return res.data;
  }

  protected async patchModel(id: string, user: Partial<UserDto>): Promise<UserDto> {
    const res = await this.axiosApiClient.get(UsersApi).patchUser({ id, user });
    return res.data;
  }

  protected async putModel(user: UserDto): Promise<UserDto> {
    const res = await this.axiosApiClient.get(UsersApi).putUser({ id: user.id, user });
    return res.data;
  }
}
